import 'bootstrap/scss/bootstrap.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGithub, faGithubAlt, faTwitch, faTwitter, faJava, faHtml5, faCss3Alt, faJs, faPhp, faReact, faGit, faBootstrap } from '@fortawesome/free-brands-svg-icons'
import { faCoffee, faFile, faUser, faHome, faAt } from '@fortawesome/free-solid-svg-icons'
import App from './App';

library.add(faFile, faCoffee, faUser, faGithub, faGithubAlt, faTwitter,
     faTwitch, faHome, faJava, faHtml5, faCss3Alt, faJs, faAt, faPhp, faReact, faGit, faBootstrap)
     
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
