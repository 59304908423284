import React from 'react'
import logoJpg from "../images/portrait.jpg"; 
import logoAvif from "../images/portrait.avif"; 

export default class Portrait extends React.Component{
  render() {
    return(
        <div className='container-sm'>
            <div className='text-center border rounded shadow-lg'>
            <picture>
              <source srcSet={logoAvif} type="image/avif" />
              <img src={logoJpg} height="auto" className='rounded' width="100%" alt="Fallback" />
            </picture>
            </div>
        </div>
     );
   }
}