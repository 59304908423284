import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class LogoContainer extends React.Component{
  render() {
    return(
        <div>
            <div className='text-white d-flex justify-content-start align-items-center h1 mt-5 flex-wrap'>
                    <a href='https://www.java.com' target={"_blank"} title="Java" rel='noreferrer'  className='link-light mx-3'>
                        <FontAwesomeIcon icon="fa-brands fa-java" />
                    </a> 
                    <a href='#whoami' title="Html5" className='link-light mx-3'>
                        <FontAwesomeIcon icon="fa-brands fa-html5" />
                    </a> 
                    <a href='#whoami' title="Css3" className='link-light mx-3'>
                        <FontAwesomeIcon icon="fa-brands fa-css3-alt" />
                    </a> 
                    <a href='#whoami' title="JavaScript" className='link-light mx-3'>
                        <FontAwesomeIcon icon="fa-brands fa-js" />
                    </a> 
                    <a href='#whoami' title="Php" className='link-light mx-3'>
                        <FontAwesomeIcon icon="fa-brands fa-php" />
                    </a> 
                    <a href='#whoami' title="React" className='link-light mx-3'>
                        <FontAwesomeIcon icon="fa-brands fa-react" />
                    </a> 
                    <a href='#whoami' title="Git" className='link-light mx-3'>
                        <FontAwesomeIcon icon="fa-brands fa-git" />
                    </a> 
                    <a href='#whoami' title="Bootstrap" className='link-light mx-3'>
                        <FontAwesomeIcon icon="fa-brands fa-bootstrap" />
                    </a> 
            </div>
            <div className='text-dark d-flex justify-content-center align-items-center h1 mt-3 mb-5'>
            </div>
        </div>
     )
   }
}