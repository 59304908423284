import React from 'react'
import WOW from 'wowjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class HeaderNav extends React.Component{
  state = {
    color: '#292b2c',
    bgcolor: 'white',
  }
  

  listenScrollEvent = e => {
    var section1 = document.getElementById('hero');
    var section2 = document.getElementById('whoami');
    var section3 = document.getElementById('resume');

    if (window.scrollY < section1.offsetTop + section1.offsetHeight/2) {
      this.setState({color: '#292b2c', bgcolor: 'white'})
    } 
    else if(window.scrollY < section2.offsetTop + section2.offsetHeight/2){
      this.setState({color: 'white', bgcolor: '#292b2c'})
    }
    else if(window.scrollY < section3.offsetTop + section3.offsetHeight/2){
      this.setState({color: '#292b2c', bgcolor: 'white'})
    }
  }

  componentDidMount() {
    document.addEventListener('touchstart', {passive: true});

    new WOW.WOW({
      live: false
    }).init();
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  render() {
    return(
          <nav id="navbar" className="navbar nav-menu">
            <ul className="list-unstyled">
              <li>
                <a href="#hero" className="nav-link rounded-pill btn btn-light py-2 px-3 mb-2"  style={{color: this.state.color,backgroundColor: this.state.bgcolor}}>
                  <span> <FontAwesomeIcon icon="home" /></span>
                </a>
              </li>
              <li>
                <a href="#whoami" className="nav-link rounded-pill btn btn-light py-2 px-3 mb-2"  style={{color: this.state.color,backgroundColor: this.state.bgcolor}}>
                  <span><FontAwesomeIcon icon="user" /></span>
                </a>
              </li>
              <li>
                <a href="#resume" className="nav-link rounded-pill btn btn-light py-2 px-3 mb-2"  style={{color: this.state.color,backgroundColor: this.state.bgcolor}}>
                  <span><FontAwesomeIcon icon="file"/></span>
                </a>
              </li>
              <li>
                <a href="#givemesomecoffee" className="nav-link rounded-pill btn btn-light py-2 px-3"  style={{color: this.state.color,backgroundColor: this.state.bgcolor}}>
                  <span> <FontAwesomeIcon icon="coffee" /></span>
                </a>
              </li>           
            </ul>
        </nav>
     )
   }
}