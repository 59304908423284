import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class SmallNav extends React.Component{
  state = {
    color: '#292b2c',
    bgcolor: 'white',
  }

  listenScrollEvent = e => {
    var section1 = document.getElementById('hero');
    var section2 = document.getElementById('whoami');
    var section3 = document.getElementById('resume');

    if (window.scrollY < section1.offsetTop + section1.offsetHeight-25) {
      this.setState({color: '#292b2c', bgcolor: 'white'})
    } 
    else if(window.scrollY < section2.offsetTop + section2.offsetHeight-25){
      this.setState({color: 'white', bgcolor: '#292b2c'})
    }
    else if(window.scrollY < section3.offsetTop + section3.offsetHeight-25){
      this.setState({color: '#292b2c', bgcolor: 'white'})
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  render() {
    return(
          <nav id="navbarsmall" className="navbar nav-menu">
            <a href="#hero" className="nav-link scrollto rounded-pill btn btn-light py-2 px-4 mx-1"  style={{color: this.state.color,backgroundColor: this.state.bgcolor}}>
              <span> <FontAwesomeIcon icon="home" /></span>
            </a>
            <a href="#whoami" className="nav-link scrollto rounded-pill btn btn-light py-2 px-4 mx-1"  style={{color: this.state.color,backgroundColor: this.state.bgcolor}}>
              <span><FontAwesomeIcon icon="user" /></span>
            </a>
            <a href="#resume" className="nav-link scrollto rounded-pill btn btn-light py-2 px-4 mx-1"  style={{color: this.state.color,backgroundColor: this.state.bgcolor}}>
              <span><FontAwesomeIcon icon="file"/></span>
            </a>
            <a href="#givemesomecoffee" className="nav-link scrollto rounded-pill btn btn-light py-2 px-4 mx-1"  style={{color: this.state.color,backgroundColor: this.state.bgcolor}}>
              <span> <FontAwesomeIcon icon="coffee" /></span>
            </a>
        </nav>
     )
   }
}