import React from 'react'
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 

export default class Resume extends React.Component{
  render() {
    return(
        <div>
            <OwlCarousel items={3} loop={true} autoplay={true} nav={false} responsive={{0:{items:1,nav:false},600:{items:3,nav:false},1000:{items:3,nav:false}}}> 
            <div className='p-5 m-4 popoutbackground h-100 blueborder'>
                <h2 className='display-6' style={{fontWeight:'bolder'}}>Education</h2>
                <div>
                    <h6>Degree in computer science</h6>
                    <h6 className='bg-secondary d-inline-flex p-2'>2019-2023</h6>
                    <p className='text-muted'>
                        Cégep Lionel-Groulx
                    </p>
                    <p className='text-white'>
                        Worked on a lot of small/big projects during the
                        4 years <br></br> and will be obtaining a Degree 
                        in computer science.
                    </p>
                </div>
            </div>
            <div className='p-5 m-4 popoutbackground h-100 blueborder'>
                <h2 className='display-6' style={{fontWeight:'bolder'}}>Work</h2>
                <div>
                    <h6>Web Developer</h6>
                    <h6 className='bg-secondary d-inline-flex p-2'>2022</h6>
                    <p className='text-muted'>
                        N'Ware Technologies
                    </p>
                    <p className='text-white'>
                        I have worked for N'ware Technologies for 8 months , 
                        helping redesigning old pages and desining new ones.
                    </p>
                </div>
            </div>
            <div className='p-5 m-4 popoutbackground h-100 blueborder'>
                <h2 className='display-6' style={{fontWeight:'bolder'}}>To be continued...</h2>
                <div>
                    <h6>The story doesn't end here</h6>
                    <h6 className='bg-secondary d-inline-flex p-2'>2021-2022</h6>
                    <p className='text-muted'>
                        It is only the beginning
                    </p>
                    <p className='text-white'>
                        Thank you for reading!
                    </p>
                </div>
            </div>
            </OwlCarousel>
        </div>
     )
   }
}