import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "./styles/Style.css"; 

import HeaderNav from './components/menus/Nav';
import SmallNav from './components/menus/SmallNav';
import Portrait from './components/Portrait';
import LogoContainer from './components/LogoContainer';
import Resume from './components/Resume';

function App() {
  return (
    <div className="content" style={{overflowX:"clip", height:"100%"}}>

      <header id="header" className="d-flex flex-column justify-content-center text-white">
        <HeaderNav />
      </header>

      <div id="smallheader" className="d-flex justify-content-center text-white fixed-top">
        <SmallNav />
      </div>

      <section className="d-flex h-100 blueborder justify-content-center overflow-hidden align-items-center container-fluid text-white px-5" id="hero">
        <div className="container-fluid mx-5">
          <div className='row wow bounceInRight'>
                <div className="col-md-9 col-sm-12 col-lg-6 d-flex flex-column mb-5 skewed">
                    <div className="row">
                      <h1 className='display-2 wow slideInLeft' style={{fontWeight:'bolder',fontFamily:'gothic'}}>Matthieu Routhier</h1>
                    </div>
                    <div className="row text-muted wow slideInRight">
                      <h3 className="display-6" style={{fontFamily:'gothic'}}>Hey! Welcome to my portfolio.</h3>
                    </div>
                    <div className='row mt-3'>
                      <div className='h3 wow fadeInUp'>
                        <a href='https://github.com/virtuaal' target={"_blank"} title="My github" rel='noreferrer'  className='link-light mx-3'>
                          <FontAwesomeIcon icon="fa-brands fa-github" />
                        </a>
                        <a href='https://twitter.com/VHDG_Official' target={"_blank"} title="My twitter" rel='noreferrer'  className='link-light mx-3'>
                          <FontAwesomeIcon icon="fa-brands fa-twitter" />
                        </a>  
                        <a href='https://www.twitch.tv/virtualhdgaming' target={"_blank"} title="My twitch" rel='noreferrer'  className='link-light mx-3'>
                          <FontAwesomeIcon icon="fa-brands fa-twitch" />
                        </a>
                        <a href='mailto:mattiro@live.fr' target={"_blank"} title="My email" rel='noreferrer'  className='link-light mx-3'>
                          <FontAwesomeIcon icon="fa-solide fa-at" />
                        </a>            
                      </div>
                    </div>      
                </div>


            <div className='skewedinversed col-md-10 col-sm-12 col-lg-6 d-flex flex-column align-items-center justify-content-center blueborder p-4 popoutbackground'>
                <code className='h3 text-info fittext wow slideInRight'><strong>{"function sendGreetingMessage() {"}<br></br></strong><br></br>
                      <p>&nbsp;&nbsp;console.writeline('Welcome to my portfolio...');<br></br>
                      &nbsp;&nbsp;var welcomeMessage = "I hope you enjoy it here!"<br></br>
                      &nbsp;&nbsp;return;<span className='typing ms-1'></span></p>
                    <strong>{"}"}</strong>
                </code>
            </div>
          </div>
        </div>
      </section>

      <section className="d-flex darkbackground blueborder flex-column h-100 justify-content-center align-items-center overflow-hidden container-fluid text-white" style={{minHeight:1200}}>
          <div className="mx-5 pt-5" id="whoami">
            <div className='container-fluid rounded wow slideInLeft' >
              <div className='row d-flex align-items-center'>
                <div className='col-md-6 pt-4 pb-4 col-sm-6 wow slideInUp mb-3'>
                    <div className="row">
                      <h1 className='text-white display-2' style={{fontWeight:'bolder',fontFamily:'gothic'}}>Who am I ?</h1>
                    </div>
                    <div className="row text-white">
                      <h3>Hey ! I am Matthieu Routhier, a 21 years old developer from Canada.</h3>
                    </div>
                    <div className="row text-muted">
                      <p className='lead quote blockquote wow bounceInUp'>
                        I have been programming for about 11-12 years now and is familiar with a lot<br></br> 
                        of languages. C#, Java, ASP .NET, VB, Web FullStack are some of the<br></br> 
                        things I am the most familiar with.
                      </p>
                    </div>
                    <div className='row text-white'>
                      <h2 style={{fontWeight:'bolder',fontFamily:'gothic'}} className="display-6">Here are some more things I work with everyday</h2>
                    </div>
                    <div className='row text-wrap ml-auto bord'>
                      <LogoContainer/>
                    </div>     
                </div>
                <div className='col-md-6 col-sm-6 wow'>
                  <div className='text-white wow slideInUp m-2'>
                    <Portrait/>         
                  </div>
                </div>
              </div>
            </div>
           
          </div>
      </section>

      <section className="d-flex blueborder darkbackground flex-column h-100 overflow-hidden justify-content-center text-white" style={{minHeight:1000}}  id="resume">
            <div className="row mt-5">
              <div className='align-items-center wow bounceInLeft text-center mb-5'>
                <h1 className='display-2' style={{fontWeight:'bolder',fontFamily:'gothic'}}>Resume</h1>
                <div className="row text-muted">  
                <h3 className='display-6'>Here are some of my achievements</h3>
                </div>    
              </div>
            </div>
            <div className='row mb-5'>
               <div className='wow bounceInRight overflow-hidden'>
                <Resume/>
              </div>
            </div>
      </section>

      <div className='blueborder darkerbackgroundinverse text-white flex-wrap d-flex align-items-center justify-content-center h3' id="givemesomecoffee" style={{height:200,minHeight:200}}>
        
        <div className='text-start wow bounceInDown'>
          Want to contact me ?
        </div>

        <div className='text-center wow bounceInDown mx-5'>
          <a href='https://github.com/virtuaal' target={"_blank"} title="My github" rel='noreferrer'  className='link-light mx-3'>
            <FontAwesomeIcon icon="fa-brands fa-github" />
          </a>
          <a href='https://twitter.com/VHDG_Official' target={"_blank"} title="My twitter" rel='noreferrer'  className='link-light mx-3'>
            <FontAwesomeIcon icon="fa-brands fa-twitter" />
          </a>  
          <a href='https://www.twitch.tv/virtualhdgaming' target={"_blank"} title="My twitch" rel='noreferrer'  className='link-light mx-3'>
            <FontAwesomeIcon icon="fa-brands fa-twitch" />
          </a>
          <a href='mailto:mattiro@live.fr' target={"_blank"} title="My email" rel='noreferrer'   className='link-light mx-3'>
            <FontAwesomeIcon icon="fa-solide fa-at" />
          </a>
        </div>

        <div className='text-end wow bounceInDown'>
          Everything you need is right here
        </div>

      </div>
    </div>
  );
}

export default App;